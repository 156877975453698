import {useAuth0} from "@auth0/auth0-react";

import * as React from 'react';
import {Grid as GridTable, _} from 'gridjs-react';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from "@mui/material/InputLabel";
import TextField from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {PageLayout} from "../components/page-layout";
import {
    getGamesCall,
    getSportsCall,
    getSeasonsCall,
    getGameStatusesCall,
} from "../services/api-interractions.service";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const DashboardPage: React.FC = () => {
    const {getAccessTokenSilently, isAuthenticated, getIdTokenClaims} = useAuth0();
    const [accessToken, setAccessToken] = useState('');
    const [userRoles, setUserRoles] = useState([]);

    const [games, setGames] = useState([]);
    const [sports, setSports] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [gameStatuses, setGameStatuses] = useState([]);
    const [contentTypes, setContentTypes] = useState([]);

    const [selectedSport, setSelectedSport] = useState('fb');
    const [selectedSeason, setSelectedSeason] = useState('2022');
    const [selectedGameStatus, setSelectedGameStatus] = useState('');
    const [selectedContentType, setSelectedContentType] = useState('postgame');
    const [selectedDate, setSelectedDate] = useState(undefined)
    const [gameLogs, setGameLogs] = useState('')

    const [modalOpen, setModalOpen] = useState(false);

    const navigate = useNavigate();

    const getAccessToken = async () => {
        const accessToken = await getAccessTokenSilently();
        setAccessToken(accessToken);
    };

    const getUserRole = async () => {
        const idTokenClaims = await getIdTokenClaims();
        const userRoles = idTokenClaims['https://nextgen.bigten.org/roles']
        setUserRoles(userRoles);
    };

    const getGames = async () => {
        const {data, error} = await getGamesCall(accessToken, selectedDate, selectedSport,
            selectedSeason, selectedGameStatus);
        setGames(data);
    };

    const getSports = async () => {
        const {data, error} = await getSportsCall(accessToken);
        setSports(data);
    };

    const getSeasons = async () => {
        const {data, error} = await getSeasonsCall(accessToken);
        setSeasons(data);
    };

    const getGameStatuses = async () => {
        const {data, error} = await getGameStatusesCall(accessToken, selectedSport)
        setGameStatuses(data);
    };

    const handleModalOpen = async (game_id) => {
        const res = await fetch(`/dashboard/logs?game_id=${game_id}&content_type=${selectedContentType}&content_league=${selectedSport}`, {
            method: "GET",
            headers: {"Content-Type": "application/json"}
        });
        const response = await res.json();
        setGameLogs(response);
        setModalOpen(true);
    };

    const handleModalClose = () => setModalOpen(false);


    useEffect(() => {
        getAccessToken().then(r => []);
    }, []);

    useEffect(() => {
        if (accessToken) {
            getUserRole().then(r => [])
        }
    }, [accessToken])

    useEffect(() => {
        if (accessToken) {
            getGames().then(r => []);
            getSports().then(r => []);
            getSeasons().then(r => []);
            getGameStatuses().then(r => []);
        }
    }, [accessToken]);

    useEffect(() => {
        if (accessToken) {
            getGames().then(r => {
            });
        }
    }, [
        selectedSport,
        selectedSeason,
        selectedGameStatus,
        selectedDate,
        accessToken
    ]);

    return (<PageLayout>
        {(isAuthenticated && userRoles.includes("HBCUDashboardUser")) && (
            <div>
                <br></br>
                <br></br>
                <Container component="main" style={{maxWidth: "mb"}} sx={{mb: 4}}>
                    <Grid container spacing={1} columns={{xs: 4, sm: 8, md: 12}}>
                        {/* Content types popup menu */}
                        <Grid item xs={2}>
                            <FormControl style={{minWidth: 120}}>
                                <InputLabel id="demo-simple-select-label">Content types</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={selectedContentType}
                                    onChange={(event) => {
                                        event.preventDefault();
                                        setSelectedContentType(event?.target?.value);
                                    }}>
                                    {contentTypes.map((contentType) => (
                                        <MenuItem key={contentType} value={contentType}>
                                            {contentType}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Sports popup menu */}
                        <Grid item xs={2}>
                            <FormControl style={{minWidth: 120}}>
                                <InputLabel id="demo-simple-select-label">Sports</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={selectedSport}
                                    onChange={(event) => {
                                        event.preventDefault();
                                        setSelectedSport(event?.target?.value);
                                    }}>
                                    {sports.map((sport) => (
                                        <MenuItem key={sport.name} value={sport.abbr}>
                                            {sport.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Seasons popup menu */}
                        <Grid item xs={2}>
                            <FormControl style={{minWidth: 120}}>
                                <InputLabel id="demo-simple-select-label">Seasons</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={selectedSeason}
                                    onChange={(event) => {
                                        event.preventDefault();
                                        setSelectedSeason(event?.target?.value);
                                    }}>
                                    <MenuItem value={''}>
                                        <em>All</em>
                                    </MenuItem>
                                    {seasons.map((season) => (
                                        <MenuItem key={season} value={season}>
                                            {season}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Game statuses popup menu */}
                        <Grid item xs={2}>
                            <FormControl style={{minWidth: 120}}>
                                <InputLabel id="demo-simple-select-label">Game statuses</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={selectedGameStatus}
                                    onChange={(event) => {
                                        event.preventDefault();
                                        setSelectedGameStatus(event?.target?.value);
                                    }}>
                                    <MenuItem value={''}>
                                        <em>All</em>
                                    </MenuItem>
                                    {gameStatuses.map((gameStatus) => (
                                        <MenuItem key={gameStatus} value={gameStatus}>
                                            {gameStatus}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="Select date to show"
                                    inputFormat="YYYY-MM-DD"
                                    value={selectedDate}
                                    onChange={(newValue) => {
                                        setSelectedDate(newValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setSelectedDate(undefined);
                                }}
                            >Reset Date</Button>
                        </Grid>
                    </Grid>
                </Container>
                <Modal
                    sx={modalStyle}
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Game processing logs
                        </Typography>
                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                            {gameLogs}
                        </Typography>
                    </Box>
                </Modal>
                <GridTable
                    columns={[
                        {name: 'Sport league', width: '12%'},
                        {name: 'Game ID', width: '12%'},
                        {name: 'Date', width: '12%'},
                        {name: 'Date Time', width: '12%'},
                        {name: 'Expected postgame delivery', width: '12%'},
                        {name: 'Expected pregame delivery', width: '12%'},
                        {name: 'Home team', width: '12%'},
                        {name: 'Away team', width: '12%'},
                        {name: 'Game Status', width: '12%'},
                        {name: 'NLG status', width: '12%'},
                        {name: 'Logs', width: '12%'}
                    ]}
                    data={() => {
                        return games.map(card => [
                            card.sport, card.game_id, card.date, card.date_time,
                            card.expected_postgame_delivery, card.expected_pregame_delivery,
                            card.home_team_market, card.away_team_market, card.status,
                            card.nlg_status,
                            _(<a href="javascript:void[0]"
                                 onClick={() => {
                                     handleModalOpen(card.game_id);
                                 }}>Show</a>)
                        ])
                    }}
                    search={true}
                    sort={true}
                    resizable={true}
                    pagination={{
                        enabled: true,
                        limit: 20,
                    }}
                    autoWidth={true}
                />
            </div>
        )}</PageLayout>);
};
