import {AxiosRequestConfig} from "axios";
import {ApiResponse} from "../models/api-response";
import {callApi} from "./api-call.service";

const apiServerUrl = new URL(process.env.REACT_APP_API_SERVER_URL);

export const getGamesCall = async (accessToken, selectedDate, selectedSport, selectedSeason, selectedGameStatus): Promise<ApiResponse> => {
    let gameDate = undefined;
    if (selectedDate === undefined) {
        gameDate = 'undefined';
    }
    else {
        gameDate = `${selectedDate.year()}-${selectedDate.month() +1}-${selectedDate.date()}`
    }
    const sport = selectedSport;
    const seasonYear = selectedSeason;
    const gameStatus = selectedGameStatus;

    const apiCallUrlWithParams = new URL('game', apiServerUrl);

    apiCallUrlWithParams.searchParams.append('sport', sport);
    apiCallUrlWithParams.searchParams.append('season_year', seasonYear);
    apiCallUrlWithParams.searchParams.append('game_status', gameStatus);
    apiCallUrlWithParams.searchParams.append('game_date', gameDate);

    const config: AxiosRequestConfig = {
        url: apiCallUrlWithParams.href,
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
    };

    const { data, error } = (await callApi({ config })) as ApiResponse;

    return {
        data,
        error,
    };
};

export const getSportsCall = async (accessToken): Promise<ApiResponse> => {
    const apiCallUrlWithParams = new URL('sport', apiServerUrl);

    const config: AxiosRequestConfig = {
        url: apiCallUrlWithParams.href,
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
    };

    const { data, error } = (await callApi({ config })) as ApiResponse;
    return {
        data,
        error,
    };
};

export const getSeasonsCall = async (accessToken): Promise<ApiResponse> => {
    const apiCallUrlWithParams = new URL('season', apiServerUrl);

    const config: AxiosRequestConfig = {
        url: apiCallUrlWithParams.href,
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
    };

    const { data, error } = (await callApi({ config })) as ApiResponse;

    return {
        data,
        error,
    };
};


export const getGameStatusesCall = async (accessToken, selectedSport): Promise<ApiResponse> => {
    const apiCallUrlWithParams = new URL('game_status', apiServerUrl);

    apiCallUrlWithParams.searchParams.append('sport', selectedSport);

    const config: AxiosRequestConfig = {
        url: apiCallUrlWithParams.href,
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
    };

    const { data, error } = (await callApi({ config })) as ApiResponse;

    return {
        data,
        error,
    };
};
