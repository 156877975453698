import React from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {App} from "./app";
import {Auth0ProviderWithNavigate} from "./auth0-provider-with-navigate";

const container = document.getElementById("page") as HTMLElement;

const root = createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth0ProviderWithNavigate>
                <App/>
            </Auth0ProviderWithNavigate>
        </BrowserRouter>
    </React.StrictMode>
);
