import {useAuth0} from "@auth0/auth0-react";
import React from "react";
import {PageLoader} from "./components/page-loader";
import {Route, Routes} from "react-router-dom";

import {CallbackPage} from "./pages/callback-page";
import {DashboardPage} from "./pages/dashboard-page";


export const App: React.FC = () => {
    const {isLoading} = useAuth0();

    if (isLoading) {
        return (
            <div className="page-layout">
                <PageLoader/>
            </div>
        );
    }
    return (
        <Routes>
            <Route index element={<DashboardPage/>}/>
            <Route path="/dashboard/" element={<DashboardPage/>}/>
            <Route path="/dashboard/callback" element={<CallbackPage/>}/>
        </Routes>
    );
};
